import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/Footers/footer.component';
import { HeaderComponent } from './components/Headears/header.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CallWriteUsComponent } from './components/CallWriteUs/callWriteUs.component';
import { RestrictionsComponent } from './components/Restrictions/restrictions.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { WhatDoComponent } from './components/what-do/what-do.component';
import { TimesPipe } from './pipes/times.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { MasterComponent } from './components/Master/master.component';
import { SummaryExtend2Component } from './components/Summary/summaryExtend2.component';
import { SummaryExtend3Component } from './components/Summary/summaryExtend3.component';
import { FormsModule } from '@angular/forms';
import { lastInsuranceComponent } from './components/LastInsurance/lastInsurance.component';
import { SearchAddressComponent } from './components/search-address/search-address.component';
import { SummaryExtend3ReturnComponent } from './components/Summary/summaryExtend3Return.component';
import { TransformDatePipe } from './pipes/formatDate.pipe';
import { FaqComponent } from './components/faq/faq.component';
import { SummaryExtend3Prev } from './components/Summary/summaryPrev/summaryExtend3Prev.component';
import { PaymentElementComponent } from './components/payment-element/payment-element.component';

@NgModule({
  declarations: [
    FaqComponent,
    FooterComponent,
    HeaderComponent,
    CallWriteUsComponent,
    RestrictionsComponent,
    PrivacyComponent,
    WhatDoComponent,
    MasterComponent,
    TimesPipe,
    SearchPipe,
    TransformDatePipe,
    SummaryExtend2Component,
    SummaryExtend3Component,
    SummaryExtend3ReturnComponent,
    SummaryExtend3Prev,
    lastInsuranceComponent,
    SearchAddressComponent,
    PaymentElementComponent,

  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    CallWriteUsComponent,
    RestrictionsComponent,
    PrivacyComponent,
    WhatDoComponent,
    MasterComponent,
    SearchPipe,
    TransformDatePipe,
    SummaryExtend2Component,
    SummaryExtend3Component,
    SummaryExtend3ReturnComponent,
    SummaryExtend3Prev,
    lastInsuranceComponent,
    SearchAddressComponent,
    FaqComponent,
    PaymentElementComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild({
      useDefaultLang: true,
      isolate: false,
      loader: { provide: TranslateLoader, useFactory: (HttpLoaderFactory), deps: [HttpClient] }
    }),

  ]
})
export class SharedModule { }
