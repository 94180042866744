<section>
          <div class="summary__head">
            <span
              >{{ "ShippingFrom" | translate}}
              {{ globals?.Order?.Customer?.CityBE?.Name }}
              &rarr;
              {{ globals?.Order?.Beneficiary?.CityBE?.Name }}</span
            >
            <span class="summary__delivery">
              {{ formatDate(globals.Order.DateWay)}} &rarr; {{ globals.Order.SelectCarrier["DeliveryDate"] | transformDate }}
              ( {{ globals.Order.SelectCarrier["DeliveryDays"]}} business days )
            </span>
          </div>
          <div class="summary__content">
            <div>
<span class="product-title">{{globals.Order.Package.Description }}
            <span class="summary__description">
              <em
                >
            MAX {{ sizes["OriginalWeight"] }} Lb | {{ sizes["Depth"] }} x
            {{ sizes["Width"] }} x {{ sizes["Height"] }} in
              </em>
              </span>
            </span>
            </div>
            <span>${{ globals.Order.SelectCarrier.Rate | number: "1.2-2" }}</span>
          </div>
</section>

<section style="margin-top: 5px;" *ngIf="globals.Order.ShipmentType == 'WayOut'">
          <div class="summary__head">
            <span
              >{{ "ShippingFrom" | translate }}
              {{ globals?.Order?.Beneficiary?.CityBE?.Name }}
              &rarr;
              {{ globals?.Order?.Customer?.CityBE?.Name }}</span
            >
            <span class="summary__delivery">
              {{ formatDate( globals.Order.DateReturn )}} &rarr;
              {{
                globals.Order.SelectReturnCarrier["DeliveryDate"]
                  | transformDate
              }}
              <span >( {{ globals.Order.SelectReturnCarrier["DeliveryDays"]}} business days )</span>
            </span>
          </div>
          <div class="summary__content">
    <div>
            <span class="product-title">{{globals.Order.Package.Description }} </span>
            <span class="summary__description">
              <em
                >
            MAX {{ sizes["OriginalWeight"] }} Lb | {{ sizes["Depth"] }} x
            {{ sizes["Width"] }} x {{ sizes["Height"] }} in
              </em>
            </span>
    </div>
          <span
            >${{
              globals.Order.SelectReturnCarrier.Rate | number: "1.2-2"
            }}</span
          >
          </div>
  <div class="summary__total">
      <strong style="color: black; text-align: right;"
            >{{ "Total" | translate }}:&nbsp;&nbsp;<span class="amount"
              >$ {{ globals.Order.SelectReturnCarrier.Rate + globals.Order.SelectCarrier.Rate | number: "1.2-2" }}</span
            ></strong>
  </div>
</section>
