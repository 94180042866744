<div>
    <input class="form-firstname form-element rounded medium" type="text" id="shipAddress" name="shipAddress" autocomplete="off"
    placeholder="{{'Address' | translate }}" onfocus="this.select()" [ngClass]="{'CodeError':(bandError)}" 
    (blur)="seleccionar()"/>
    <input id="postcode" name="postcode" required style="display: none;" />
    <input id="postcity" name="postcity" required style="display: none;" />
    <input id="postcityCode" name="postcityCode" required style="display: none;" />
    <button type="button" style="display:none;" id="botonField" (click)="seleccionar()">Save address</button>
  </div>
  
