<div class="cart-overview">
  <section class="summary__table">
    <div class="summary__content">
      <div>
        <span>Origin</span>
        <span class="summary__description">
          <em>{{globals.Order.Customer.Name | titlecase }} {{globals.Order.Customer.LastName | titlecase}}</em>
          <em *ngIf="globals.Order.Customer.CompanyName">{{globals.Order.Customer.CompanyName}}</em>
          <em> {{globals.Order.Customer.Address}} {{globals.Order.Customer.CityBE.Code}} -
            {{globals.Order.Customer.ZipCode}} </em>
          <em>{{globals.Order.Customer.MobilePhone}}</em>
          <em *ngIf="!globals.Order.PickupIn">Drop off at any UPS store</em>
        </span>
      </div>
      <button type="button" class="summary__edit" [routerLink]="['/Sender']">Edit</button>
    </div>
    <div class="summary__content">
      <div>
        <span>Destination</span>
        <span class="summary__description">
          <em>{{globals.Order.Beneficiary.Name | titlecase }} {{globals.Order.Beneficiary.LastName | titlecase}}</em>
          <em *ngIf="globals.Order.Beneficiary.CompanyName">{{globals.Order.Beneficiary.CompanyName}}</em>
          <em> {{globals.Order.Beneficiary.Address}} {{globals.Order.Beneficiary.CityBE.Code}} -
            {{globals.Order.Beneficiary.ZipCode}} </em>
          <em>{{globals.Order.Beneficiary.MobilePhone}}</em>
        </span>
      </div>
      <button type="button" class="summary__edit" [routerLink]="['/Beneficiary']">Edit</button>
    </div>
    <br>
    <div class="summary__head">
      <span>{{ "ShippingFrom" | translate}}
        {{ globals?.Order?.Customer?.CityBE?.Name }}
        &rarr;
        {{ globals?.Order?.Beneficiary?.CityBE?.Name }}</span>
      <span class="summary__delivery">
        {{ formatDate(globals.Order.DateWay)}} &rarr; {{ globals.Order.SelectCarrier["DeliveryDate"] | transformDate }}
        ( {{ globals.Order.SelectCarrier["DeliveryDays"]}} business days )
      </span>
    </div>
    <div class="summary__content">
      <div>
        <span class="product-title">{{globals.Order.Package.Description }} </span>
        <span class="summary__description">
          <em>
            MAX {{ sizes["OriginalWeight"] }} Lb | {{ sizes["Depth"] }} x
            {{ sizes["Width"] }} x {{ sizes["Height"] }} in
          </em>
        </span>
      </div>
      <span>${{ globals.Order.CalculateResponse.Rate | number: "1.2-2" }}</span>
    </div>
    <div class="summary__content" *ngIf="globals.Order.TotalInsurance > 0">
      <div>
        <span>{{ "Insurance" | translate }}</span>
        <span class="summary__description">
          <em>{{ "InsuredValue" | translate }} ${{
            globals.Order.TotalInsurance | number: "1.2-2"
            }}</em>
        </span>
      </div>
      <span>${{
        globals.Order.CalculateResponse.Insurance | number: "1.2-2"
        }}</span>
    </div>
    <div class="summary__content" *ngIf="globals.Order.PickupIn">
      <div>
        <span class="product-title">Pickup </span>
        <span class="summary__description">
          <em>{{ datePickup }}</em>
        </span>
      </div>
      <span class="summary__product">${{globals.Order.RatePickup}}</span>
    </div>
  <div class="summary__content" *ngIf="globals.Order.CalculateResponse?.AddFee>0" >
      <div>
        <span class="product-title">Processing Fee </span>
        
      </div>
      <span class="summary__product">${{globals.Order.CalculateResponse?.AddFee | number: "1.2-2"}}</span>
    </div>
    <div class="summary__discount">
      <input *ngIf="!showLabel" [(ngModel)]="objPromotion.Code" placeholder="{{ 'PromotionCode' | translate }}"
        class="form-element rounded small" id="txtPromotionCodE" style="
              width: 125px;
              padding : 1.14rem 1.5rem;
              padding-left: 5px;
              padding-right: 5px;
              height: 100%;
              margin: 0 !important;
            " />
      &nbsp;
      <input *ngIf="!showLabel" type="button" (click)="validatePromoCode()" value="&rarr;"
        class="summary__button" />&nbsp;&nbsp;
      <span>{{ sign }}{{ valueDiscount }} </span>
    </div>

    <div id="SubmitPayment" class="summary__total">
      <strong style="color: black">{{ "Total" | translate }}:&nbsp;&nbsp;<span class="amount">$ {{ totalWithDiscount |
          number: "1.2-2" }}</span></strong>
    </div>
  </section>
</div>
