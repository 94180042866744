import { CityBE } from './CityBE';

export class BeneficiaryBE {
  constructor() {
    this.CityBE = new CityBE();
    this.Address = '';
    this.ZipCode = '';
  }
  Id: number;
  Name: string;
  LastName: string;
  Address: string;
  Address2: string;
  ZipCode: string;
  Phone: string;
  MobilePhone: string;
  Email: string;
  JoinDate: string;
  IsEnabled: boolean;
  CityBE = new CityBE();
  ResponseCode: number;
  CompanyName: string;
}

