import { Component, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})


export class FaqComponent implements OnInit {
  lstTranslates: { Question: string, Response: string, Number: string }[] = [];
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get('NQuestions').subscribe((translated: string) => {
      let questions: String = translated;
      var i: number;
      for (i = 1; i <= Number(questions); i++) {
        this.lstTranslates.push({ "Question": ("question" + String(i)), "Response": ("question" + String(i) + "S"), "Number": String(i) });
      }
    });
  }

  public codigoAscii(num: number) {
    switch (num) {
      case 1: return 'UN';
      case 2: return 'DOS';
      case 3: return 'TRES';
      case 4: return 'CUATRO';
      case 5: return 'CINCO';
      case 6: return 'SEIS';
      case 7: return 'SIETE';
      case 8: return 'OCHO';
      case 9: return 'NUEVE';
    }
    return "xx";
  }
}
