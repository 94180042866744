import { Component, EventEmitter, Output } from '@angular/core';

declare var selectFirstOptionOnEnter: any;
declare var fillInAddress: any;
declare var google: any;
declare var autocomplete: any;
@Component({
  selector: 'app-search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.css']
})
export class SearchAddressComponent {
  @Output() code = new EventEmitter<string>();
  @Output() city = new EventEmitter<string>();
  @Output() cityCode = new EventEmitter<string>();
  @Output() shipAddress = new EventEmitter<string>();

  bandError:boolean=false;

  ngAfterViewInit(): void {
    selectFirstOptionOnEnter(document.getElementById("shipAddress"));
    const options = {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["address_components"],
      types: [],
    };
    autocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>document.getElementById("shipAddress"), options);
    autocomplete.addListener("place_changed", fillInAddress);
  }

  public seleccionar():void {
    let postalField = <HTMLInputElement>document.getElementById("postcode");
    let cityField = <HTMLInputElement>document.getElementById("postcity");
    let cityCodeField = <HTMLInputElement>document.getElementById("postcityCode");
    let shipAddress = <HTMLInputElement>document.getElementById("shipAddress");
    this.shipAddress.emit(shipAddress.value);
    if(postalField.value==null||postalField.value==""){
      if(cityField.value==null||cityField.value==""){
        this.bandError=true;
      }else{
        this.city.emit(cityField.value);
        this.cityCode.emit(cityCodeField.value);
        this.bandError=false;
      }
    }else{
      this.code.emit(postalField.value);
      this.city.emit(cityField.value);
      this.cityCode.emit(cityCodeField.value);
      this.bandError=false;
    }
  }
}
