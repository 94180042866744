import { CustomerBE } from './CustomerBE';
import { BeneficiaryBE } from './BeneficiaryBE';
import { ProviderBE } from './ProviderBE';
import { PackageBE } from './PackageBE';
import { AdditionalInfoBE } from './AdditionalInfoBE';
import { CalculateResponseBE } from './CalculateResponseBE';
import { PackageOrderBE } from './PackageOrderBE';
import { CustomerCreditCardBE } from './CustomerCreditCardBE';
import { LanguageBE } from './LanguageBE';
import { CityBE } from './CityBE';
import { OrderDetailBE } from './OrderDetailBE';
import { CustomResponseBE } from './CustomResponseBE';
import { PromotionCodeBE } from './PromotionCodeBE';
import { CategorySelectBE } from './category-select-be';
import { PickupBE } from './PickupBE';

export class ZipCodeBE {
  ZipCode: string = '';
  ZipCity: string = '';
  ZipState: string = '';
  ZipState2: string = '';
  ResponseCode: any = 0;
}

export class IdContentBE {
  IdCat: any = '';
  Declare: number;
}

export class OrderBE {
  constructor() {
    this.Customer = new CustomerBE();
    this.Beneficiary = new BeneficiaryBE();
    this.LocalProvider = new ProviderBE();
    this.ForeignProvider = new ProviderBE();
    this.Package = new PackageBE();
    this.CustomerCreditCard = new CustomerCreditCardBE();
    this.CustomerCreditCard.Customer = new CustomerBE();
    this.Language = new LanguageBE();
    this.AdditionalInfo = new AdditionalInfoBE();
    this.City = new CityBE();
    this.OrderDetail = new OrderDetailBE();
    this.CustomResponse = new CustomResponseBE();
    this.PromotionCode = new PromotionCodeBE();
    this.SKYPREFIXNUMBER = '';
    this.SKYBILLNUMBER = '';
    this.lstIdContents = '';
    this.lstContentsEsp = [];
    this.bandItems = false;
    this.ShipmentType = 'WayIn';
    this.DateWay = '';
    this.Step = '';
    this.PickupIn = true;
    this.PickupOut = true;
  }
  Id: number = 0;
  Hash: string;
  ReceiptNumber: string;
  LocalProvider: ProviderBE;
  LocalProviderReturn: ProviderBE;
  ForeignProvider: ProviderBE;
  ForeignProviderReturn: ProviderBE;
  Customer = new CustomerBE();
  Beneficiary = new BeneficiaryBE();
  TotalWeight: number;
  DeclaredValue: number = 0;
  TotalInsurance: number;
  TotalInsuranceReturn: number;
  TotalTaxes: number;
  TotalValue: number;
  TotalFreight: number;
  TotalPickup: string;
  InsuredValue: number;
  InsuredValueReturn: number;
  RateReturn: number;
  Package: PackageBE;
  Carrier: Carrier[];
  CalculateResponse: CalculateResponseBE;
  Packages: PackageOrderBE[] = [];
  CustomerCreditCard: CustomerCreditCardBE;
  Language: LanguageBE;
  AdditionalInfo: AdditionalInfoBE;
  City: CityBE;
  Tracking: string;
  TrackingReturn: string;
  OrderDetail: OrderDetailBE;
  isCheckDocuments: boolean;
  CustomResponse: CustomResponseBE;
  Discount: number;
  Step: String;
  PromotionCode: PromotionCodeBE;
  PaymentMethodTotal: String;
  IsInProccess: boolean;
  isCustomerShipping: boolean = false;
  USPSRate: number;
  isPaid: boolean;
  Gclid: string;
  ZipOrigination: ZipCodeBE = new ZipCodeBE();
  ZipDestination: ZipCodeBE = new ZipCodeBE();
  Created: string;
  // lstContents: ContentsBE[] = [];
  SKYPREFIXNUMBER: string = '';
  SKYBILLNUMBER: string = '';
  lstContents: CategorySelectBE[] = [];
  lstIdContents: string = '';
  lstContentsEsp: string[] = [];
  CarrierMessages: string[] = [];
  nationalOrder: boolean;
  SelectCarrier: Carrier;
  SelectReturnCarrier: Carrier;
  bandItems: boolean;
  ShipmentType: 'WayIn' | 'WayOut';
  ShipmentIdReturn: string;
  DateReturn?: string;
  DateWay?: string;
  insuranceType: 'WayIn' | 'WayOut';
  PickupId: string;
  PickupIdReturn?: string;
  RatePickup: string;
  TotalRatePickup: string;
  TotalRatePickupReturn?: string;
  SelectPickup: PickupBE;
  SelectReturnPickup?: PickupBE;
  MinDatePickup: string;
  MaxDatePickup: string;
  MinDatePickupReturn?: string;
  MaxDatePickupReturn?: string;
  TrackingId?:string;
  PickupIn: boolean;
  PickupOut?: boolean;
  HoldForPickup: boolean = false;
  HoldForPickupReturn : boolean = false;
  ClientSecret?: string;
  TransactionId?: string;
}
export interface Carrier {
  DeliveryDays: string;
  Name: string;
  Rate: number;
  ServiceDescription: string;
  ServiceType: string;
  DeliveryDate: string;
}

export enum ContentsType {
  gift = 1,
  merchandise,
  returned_goods,
  documents,
  sample,
  other,
}
