<footer class="footer footer-light with-border footerMain">
  <div class="lxbrow large center">
    <div class=" column width-12 widget center scroll-link cursorPointer pt-3">
      <div class="mx-auto" style="width: 200px; ">
        <a href="https://www.bagtotee.com" class="  p-0 m-0">
          <img src="./assets/images/logo-dark.svg" class="p-0 m-0" alt="footer" loading="lazy" />
        </a>
      </div>
    </div>
    <div class="column width-12">
      <div class="lxbrow flex two-columns-on-tablet">
        <div class="column width-4">
          <div class="widget center">
            <h3 class="widget-title mb-30 footerMain_title">{{'Company' | translate}}</h3>
            <ul class="footerMain_list">
              <li><a href="#" (click)="moverseElemento('AboutUs')" class="a scroll-link">Customer Support</a>
              </li>
              <li><a [routerLink]="['/Terms']" class="scroll-link">{{'TermsAndConditions' | translate}}</a></li>
              <li><a [routerLink]="['/Privacy']" class="scroll-link">{{'NoticeofPrivacy' | translate}}</a></li>
              <li><a [routerLink]="['/Tracking']" class="scroll-link">{{'Tracking' | translate}}</a></li>
              <li><a [routerLink]="['/Main']" class="scroll-link">{{'NewSend' | translate}}</a></li>
            </ul>
          </div>
        </div>
        <div class="column width-4">
          <div class="widget center">
            <h3 class="widget-title mb-30 footerMain_title">{{'Information' | translate}}</h3>
            <ul class="footerMain_list">
              <!-- href="#how-it-works" (click)="goHowItWorks()" -->
              <li><a href="https://www.bagtotee.com/#howitworks" class="scroll-link">How it works</a>
              </li>
              <!-- <li><a [routerLink]="['/Faq']"  class="scroll-link">{{'FrequentQuestion' | translate}}</a></li> -->
              <li><a [routerLink]="['/Restrictions']" class="scroll-link">{{'restricciones' | translate}}</a></li>
              <li><a [routerLink]="['/Login']" class="scroll-link ">{{'MyAccount' | translate}}</a></li>
            </ul>
          </div>
        </div>
        <div class="column width-4">
          <div class="widget center">
            <h3 class="widget-title mb-30 footerMain_title">Customer Support</h3>
            <ul class="footerMain_list">
              <li><a href="mailto:support@bagtotee.com">
                  <i class="fa fa-envelope-o " aria-hidden="true"></i> support@bagtotee.com<br>
                </a></li>
              <li><a href="tel:+18507135098">
                  <i class="fa fa-phone-square" aria-hidden="true"></i> +1 (850) 713-5098<br>
                </a></li>
              <li><i class="fa fa-map-marker" aria-hidden="true"></i> {{'DirLb1' | translate}}</li>
              <li>{{'DirLb2' | translate}}</li>
              <!-- <li>{{'DirLb3' | translate}}</li> -->
              <li>
                <a href="sms:+18507135098">
                  <i class="fa fa-whatsapp" aria-hidden="true"></i>SMS</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="widget center">
        <p>
          <img src="./assets/images/payment_logos.webp" width="119px" height="30px" alt="backdrop" loading="lazy" />
        </p>
      </div>
      <div class="social center pb-20">
        <a target="_blank" href="https://www.instagram.com/bagtotee/"> <img src="./assets/icons/instagram.svg"
            width="25" alt="instagram" loading="lazy" /></a>&nbsp;&nbsp;
        <a target="_blank" href="https://www.tiktok.com/@bagtotee"> <img src="./assets/icons/tiktok.svg" alt="tiktok"
            loading="lazy" width="25" /></a>
      </div>
      <p class="copyright">&copy; 7LOC LLC. All Rights Reserved. 2024<br>
        <!-- Made with love by young coders -->
      </p>
      <!-- <i class="fa fa-caret-square-o-up scroll-link cursorPointer" aria-hidden="true" (click)="irInicio()"></i> -->
    </div>
  </div>
</footer>
