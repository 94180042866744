import { CustomerBE } from "./CustomerBE";
import { TypeCreditCardBE } from "./TypeCreditCardBE";


export class CustomerCreditCardBE {
    constructor(){
        this.Customer = new CustomerBE();
        this.TypeCreditCard = new TypeCreditCardBE();
    }
    Id: number;
    Customer: CustomerBE;
    Name: string;
    Number: string;
    ExpirationMM: string;
    ExpirationYY: string;
    TypeCreditCard: TypeCreditCardBE
    Cvv: string;
    IsCvvExists: Boolean;
    ZipCode: string;
}