import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../Entities/Globals';
import { PromotionCodeBE } from '../../Entities/PromotionCodeBE';
import { OrdenService } from '../../services/orden.service';
import { ComplemtService } from '../../services/complemt.service';
import { Observable } from 'rxjs';
import { PackageBE } from '../../Entities/PackageBE';
import { CountriesService } from '../../services/countries.service';
import { CountryBE } from '../../Entities/CountryBE';

@Component({
  selector: 'app-summaryExtend3',
  styleUrls: ['./summaryExtend3.component.scss'],
  templateUrl: './summaryExtend3.component.html',
})
export class SummaryExtend3Component implements OnInit {
  // @Input() globalSummary: Globals;
  isMessage: boolean;
  Message: string;
  globals: any;
  valueDiscount: number = 0;
  totalWithDiscount: number = 0;
  objPromotion: PromotionCodeBE = new PromotionCodeBE();
  showLabel: boolean = false;
  OriginalWeight: string = '';
  Height: string = '';
  Width: string = '';
  Depth: string = '';
  sign: string = '-$';
  measure: string;
  codISO2 = '';

  country: string;
  Code: string = '';

  nationalOrder: boolean;

  zipOrigination$: Observable<any>;
  zipDestination$: Observable<any>;
  pickup: number;

  public datePickup: string = '';
  public sizes: any;

  constructor(
    private global: Globals,
    private ordenService: OrdenService,
    private translate: TranslateService,
    public util: ComplemtService,
    private countryService: CountriesService,
  ) {
    if (this.ordenService.isOrder) {
      this.global.Order = JSON.parse(this.ordenService.getInfoOrder());
      this.global.Order.PaymentMethodTotal = 'CC';
      this.codISO2 = this.global.Order.Package.IsoCountry;
      if (!this.global.Order.nationalOrder)
        this.codISO2 = this.global.Order.Package.CityBE.CountryBE.ISO2;
    }
    this.init();
    this.getIsoCountry();
    this.sizes = this.globals.Order.Packages[0];
  }

  ngOnInit(): void {
    this.datePickup = this.formatDate(this.globals.Order.MinDatePickup);
  }

  getZipCodes() {
    const zipCodes: PackageBE = this.globals.Order.Package;
    this.zipOrigination$ = this.util.executeMethod('Order_Zipcode', {
      codigo: zipCodes['ZipOrigination'],
    });
    this.zipDestination$ = this.util.executeMethod('Order_Zipcode', {
      codigo: zipCodes['ZipDestination'],
    });
  }

  private getIsoCountry() {
    this.country = JSON.parse(this.countryService.getInfoCountryBE()).find(
      (value: CountryBE) => this.codISO2 == value['ISO2'],
    );
  }

  formatDate(date: any): string {
    if (!date) return;
    const newDate = new Date(
      parseInt(date.replace(/\/Date\((.*?)[+-]\d+\)\//i, '$1'), 10),
    );
    return newDate.toLocaleDateString('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    });
  }

  init() {
    this.isMessage = false;
    let obj = Object.assign({}, this.global);
    this.globals = obj;

    this.pickup = this.globals.Order.CalculateResponse.Pickup;

    this.nationalOrder = this.globals.Order.nationalOrder;

    this.getZipCodes();
    this.globals.Order.TotalValue = this.global.Round(
      this.globals.Order.TotalValue,
      2,
    );
    this.globals.Order.Package.OriginalWeight = this.global.Order.Packages[0]
      .OriginalWeight
      ? this.global.Order.Packages[0].OriginalWeight
      : 0;
    if (this.globals.Order.Package.Pounds != undefined) {
      if (
        this.globals.Order.PromotionCode != null &&
        this.globals.Order.PromotionCode.Response == 1
      ) {
        if (this.globals.Order.PromotionCode.Code.includes('COD')) {
          this.globals.Order.Discount =
            this.globals.Order.PromotionCode.Percent;
          this.valueDiscount = +this.global.Round(
            this.globals.Order.CalculateResponse.Rate *
            (this.globals.Order.Discount / 100),
            2,
          );
          this.totalWithDiscount =
            this.globals.Order.CalculateResponse.Rate + this.valueDiscount;
          this.totalWithDiscount = +this.global.Round(
            this.totalWithDiscount +
            this.pickup +
            this.globals.Order.CalculateResponse.Taxes +
            this.globals.Order.CalculateResponse.Insurance
            + this.globals.Order.CalculateResponse.AddFee,
            2,
          );
          this.sign = '+$';
        } else {
          if (this.globals.Order.PromotionCode.Percent > 0) {
            this.globals.Order.Discount =
              this.globals.Order.PromotionCode.Percent;
            this.valueDiscount = +this.global.Round(
              this.globals.Order.CalculateResponse.Rate *
              (this.globals.Order.Discount / 100),
              2,
            );
            this.totalWithDiscount =
              this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
            this.totalWithDiscount = +this.global.Round(
              this.totalWithDiscount +
              this.pickup +
              this.globals.Order.CalculateResponse.Taxes +
              this.globals.Order.CalculateResponse.Insurance
              + this.globals.Order.CalculateResponse.AddFee,
              2,
            );
          } else {
            this.globals.Order.Discount = 0;
            this.valueDiscount = this.globals.Order.PromotionCode.FixedValue;
            this.totalWithDiscount =
              this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
            this.totalWithDiscount = +this.global.Round(
              this.totalWithDiscount +
              this.pickup +
              this.globals.Order.CalculateResponse.Taxes +
              this.globals.Order.CalculateResponse.Insurance
              + this.globals.Order.CalculateResponse.AddFee,
              2,
            );
          }
          this.sign = '-$';
        }
      } else {
        this.valueDiscount = +this.global.Round(
          this.globals.Order.CalculateResponse.Rate *
          (this.globals.Order.Discount / 100),
          2,
        );
        this.totalWithDiscount =
          this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
        this.totalWithDiscount = +this.global.Round(
          this.totalWithDiscount +
          this.pickup +
          this.globals.Order.CalculateResponse.Taxes +
          this.globals.Order.CalculateResponse.Insurance
          + this.globals.Order.CalculateResponse.AddFee ,
          2,
        );
      }
    }
    /*
        if (this.globals.Order.Step == "3") {
            this.showLabel = true;
        }

        if (this.globals.Order.Step == "5") {
            this.showLabel = true;
            this.globals.Order.TotalValue = this.global.Order.CalculateResponse.Rate + this.global.Order.CalculateResponse.Insurance + this.global.Order.CalculateResponse.Taxes
            this.globals.Order.TotalValue = this.global.Round(this.globals.Order.TotalValue,2)

            this.global.resetGeneralData();
        }
        */
    var inchesConvertion = 2.54;
    this.measure = this.globals.Order.Package.Measure.slice(0, 2);
    if (this.globals.Order.Package.MeasureWeight != 'Lb') {
      this.OriginalWeight = (
        this.globals.Order.Packages[0].Weight / 2.2046
      ).toString();
    } else {
      this.OriginalWeight = this.globals.Order.Packages[0].Weight.toString();
    }
    if (this.globals.Order.Package.Measure != 'Inches') {
      this.Depth = (
        this.globals.Order.Packages[0].Depth * inchesConvertion
      ).toString();
      this.Height = (
        this.globals.Order.Packages[0].Height * inchesConvertion
      ).toString();
      this.Width = (
        this.globals.Order.Packages[0].Width * inchesConvertion
      ).toString();
    } else {
      this.Depth = this.globals.Order.Packages[0].Depth.toString();
      this.Height = this.globals.Order.Packages[0].Height.toString();
      this.Width = this.globals.Order.Packages[0].Width.toString();
    }
  }

  validatePromoCode() {
    this.objPromotion.TotalValue = this.globals.Order.CalculateResponse.Rate;

    this.global.executeMethod(
      'PromotionCode_SelectCommand',
      { promotion: this.objPromotion },
      (x) => {
        this.objPromotion = x.PromotionCode_SelectCommandResult;
        if (this.objPromotion.Response == 1) {
          this.isMessage = false;
          if (this.objPromotion.Code.includes('COD')) {
            this.globals.Order.Discount = this.objPromotion.Percent;
            this.valueDiscount = +this.global.Round(
              this.globals.Order.CalculateResponse.Rate *
              (this.globals.Order.Discount / 100),
              2,
            );
            this.totalWithDiscount =
              this.globals.Order.CalculateResponse.Rate + this.valueDiscount;
            this.totalWithDiscount = +this.global.Round(
              this.totalWithDiscount +
              this.pickup +
              this.globals.Order.CalculateResponse.Taxes +
              this.globals.Order.CalculateResponse.Insurance
              + this.globals.Order.CalculateResponse.AddFee,
              2,
            );
            this.sign = '+$';
          } else {
            if (this.objPromotion.Percent > 0) {
              this.globals.Order.Discount = this.objPromotion.Percent;
              this.valueDiscount = +this.global.Round(
                this.globals.Order.CalculateResponse.Rate *
                (this.globals.Order.Discount / 100),
                2,
              );
              this.totalWithDiscount =
                this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
              this.totalWithDiscount = +this.global.Round(
                this.totalWithDiscount +
                this.pickup +
                this.globals.Order.CalculateResponse.Taxes +
                this.globals.Order.CalculateResponse.Insurance
                + this.globals.Order.CalculateResponse.AddFee,
                2,
              );
            } else {
              this.globals.Order.Discount = 0;
              this.valueDiscount = this.objPromotion.FixedValue;
              this.totalWithDiscount =
                this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
              this.totalWithDiscount = +this.global.Round(
                this.totalWithDiscount +
                this.pickup +
                this.globals.Order.CalculateResponse.Taxes +
                this.globals.Order.CalculateResponse.Insurance
                + this.globals.Order.CalculateResponse.AddFee,
                2,
              );
            }
            this.sign = '-$';
          }
          this.global.Order.PromotionCode = this.objPromotion;
          this.global.saveData();
        } else if (this.objPromotion.Response == -1) {
          this.isMessage = true;
          this.Message = this.translate.instant('CodeInvalid');
        } else if (this.objPromotion.Response == -100) {
          this.isMessage = true;
          this.Message = this.translate.instant('CodeExpired');
        } else if (this.objPromotion.Response == -200) {
          this.isMessage = true;
          this.Message = this.translate.instant('CodeMaxUse');
        }
      },
    );
  }

  getGlobalsData() {
    this.global.getData();
    this.init();
  }
}
