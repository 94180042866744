import { Component, Output, EventEmitter } from "@angular/core";
import { Globals } from "../../Entities/Globals";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { InsuranceResticionsBE } from "../../Entities/InsuranceRestrictionsBE";
import { LogService } from '../../services/log.service';
import { SegmentService } from '../../services/segment.service';
import { OrdenService } from "../../services/orden.service";
import { OrderBE } from "../../Entities/OrderBE";
import { ACContactBE } from "../../Entities/accontact-be";
import { ComplemtService } from "../../services/complemt.service";

@Component({
  selector: 'app-last-insurance',
  templateUrl: './lastInsurance.component.html',
  styleUrls: ['./lastInsurance.component.scss'],
})

export class lastInsuranceComponent {
  hideMessage: boolean = true;
  hideMessage2: boolean = true;
  hideMessage3: boolean = true;
  dblNewInsurance: number;
  responseOk: boolean = true;
  errorMessage: string;
  isDisabled: boolean = false;
  insurance: InsuranceResticionsBE;
  public loading = false;
  closeResult: string;
  dblSeguroMax: number;
  insuranceAplied: boolean = null;
  insuranceApliedMessage: string;

  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() nextEvent = new EventEmitter<string>();
  @Output() CCErrorEvent = new EventEmitter<string>();
  @Output() updateSumamaryEvent = new EventEmitter<string>();

  constructor(public globals: Globals,
    private router: Router,
    private translate: TranslateService,
    private logService: LogService,
    private ordenService: OrdenService,
    public util: ComplemtService,
    private segmentService: SegmentService) {
    this.globals.getData();
    this.globals.Order;
    this.logService.insertLocalLog(null, 'Insurance - constructor()', this.globals.Order);
    this.insurance = new InsuranceResticionsBE();
    this.insurance.CountryBE = this.globals.Order.Beneficiary.CityBE.CountryBE;
    this.globals.executeMethod("InsuranceRestrictions_SelectCommand", { insuranceRestrictionsBE: this.insurance }, (x) => {
      this.insurance = x.InsuranceRestrictions_SelectCommandResult;

    });
    if (this.globals.Order.TotalTaxes <= 100) {
      this.globals.Order.TotalInsurance = this.globals.tryDouble(100);
    } else {
      this.globals.Order.TotalInsurance = this.globals.Order.TotalTaxes;
    }
    this.dblNewInsurance = this.globals.tryDouble(this.globals.Order.TotalInsurance);
    this.recalculate();

  }

  getGlobalsData() {
    this.globals.getData();
  }

  recalculate() {
    this.logService.insertLocalLog(null, 'Insurance - recalculate()', this.globals.Order);
    this.hideMessage3 = true;
    this.insuranceAplied = null;
    this.globals.Order.TotalInsurance = this.globals.tryDouble(this.dblNewInsurance);
    this.globals.executeMethod("Insurance_SelectCommandCalculate", { orderBE: this.globals.Order }, (x) => {
      this.globals.Order = x.Insurance_SelectCommandCalculateResult;
      this.globals.Order.InsuredValue = this.globals.tryDouble(this.dblNewInsurance);
      this.globals.saveData();
      this.updateSumamaryEvent.emit(null);

    });
  }

  onAccept(e) {
    if (this.dblNewInsurance == 0) {
      this.dblNewInsurance = 100;
    }
    this.recalculate();
    if ((this.globals.Order.CalculateResponse.Insurance > 0 || this.globals.Order.TotalInsurance > 0) && this.dblNewInsurance > 0) {
      // e.target.href = "#SubmitPayment";
      this.recalculate();
      this.insuranceAplied = true;
      this.insuranceApliedMessage = this.translate.instant('InsuranceApliedMessage');
      // $("#goToSubmitPayment").click();
    } else {
      // e.target.href = "";
      this.insuranceAplied = null;
      this.insuranceApliedMessage = this.translate.instant('SelectInsuranceValue');
      this.recalculate();
    }
    this.hideMessage3 = false;
  }

  onDecline(e) {
    // e.target.href = "#SubmitPayment";
    this.dblNewInsurance = 0;
    this.recalculate();
    this.insuranceAplied = false;
    this.hideMessage3 = false;
    this.insuranceApliedMessage = this.translate.instant('InsuranceDeclineMessage');
    //$("#goToSubmitPayment").click();
  }

  next(orderHoldId?) {
    this.getGlobalsData();
    this.logService.insertLocalLog(null, 'Insurance - next() "Aceptar"', this.globals.Order);
    // this.hideMessage2 = false;
    // this.hideMessage = true;
    //this.dblSeguroMax = this.globals.Order.TotalTaxes * 1.5;
    this.globals.Order.TotalInsurance = this.globals.tryDouble(this.dblNewInsurance);
    this.globals.executeMethod("Insurance_SelectCommandCalculate", { orderBE: this.globals.Order }, (x) => {
      this.globals.Order = x.Insurance_SelectCommandCalculateResult;
      this.globals.Order.InsuredValue = this.globals.tryDouble(this.dblNewInsurance);
      this.globals.saveData();

      //if (this.dblSeguroMax >= this.dblNewInsurance) {
      if (this.insurance.InsuranceRestrictionId > 0 && (this.insurance.MinValue > this.globals.tryDouble(this.dblNewInsurance) || this.insurance.MaxValue < this.globals.tryDouble(this.dblNewInsurance))) {
        this.loadingChange.emit(false);
        this.hideMessage = false;
        this.errorMessage = this.translate.instant(this.insurance.Message.toString()).replace("@minValue", this.insurance.MinValue.toString()).replace("@maxValue", this.insurance.MaxValue.toString());
        this.nextEvent.emit(this.errorMessage);
      } else {
        this.isDisabled = true;
        this.segmentService.getGclid(gclid => {
          this.globals.Order.Gclid = gclid;
         
          this.generar_InsertOrder(orderHoldId);
        });
      }
    });
    //} else {
    //    this.hideMessage = false;
    //    this.errorMessage = "El valor asegurado no puede ser mayor a $" + this.dblSeguroMax.toString() + " Este valor se determina por el valor que ud declaro para impuestos.";
    //}
  }

  generar_InsertOrder(orderHoldId){
    this.globals.executeMethod('Order_InsertCommandDirect', { orderBE: this.globals.Order }, (data) => {
      this.isDisabled = false;
      if (data.Order_InsertCommandDirectResult.CustomResponse != undefined && data.Order_InsertCommandDirectResult.CustomResponse != null) {
        console.log('data',data);
        if (data.Order_InsertCommandDirectResult.ClientSecret == null 
            || data.Order_InsertCommandDirectResult.ClientSecret == ""
            || data.Order_InsertCommandDirectResult.ClientSecret == undefined
           ) { 
          this.logService.insertLocalLog(null, 'Insurance -Order_InsertCommandDirect-OK', data);
          this.responseOk = true;
          this.loading = false;
          this.loadingChange.emit(false);
          this.globals.Order = data.Order_InsertCommandDirectResult;
          this.globals.Order.Step = "5";
          this.globals.Order.IsInProccess = false;
          //this.enviaActiveCampanign(this.globals.Order);
          this.globals.saveData();
          if (orderHoldId) {
            let tmpOrder = this.globals.Order;
            tmpOrder.Id = orderHoldId;
            this.globals.executeMethod('OrderHold_SetPaidCommand', { orderBE: tmpOrder }, res => { });
          }
          this.router.navigate(['./Confirmation']);
          //this.tmpOrder.Step="2";
          this.ordenService.OrderOUT();
        } else {
          // this.responseOk = false;
          // this.loading = false;
          // this.hideMessage2 = true;
          // this.hideMessage = false;
          this.logService.insertLocalLog(null, 'Insurance -Order_InsertCommandDirect-error NULL', data??{});
          this.errorMessage = `${data.Order_InsertCommandDirectResult.CustomResponse.ErrorText} - ${this.translate.instant("ValidateCCData")}`
          this.nextEvent.emit(this.errorMessage);
          this.loadingChange.emit(false)
          if (data.Order_InsertCommandDirectResult.CustomResponse.ErrorText == "This transaction has been declined.") {
            this.CCErrorEvent.emit(null);
          }
        }
      }
      else {
        // this.loading = false;
        // this.responseOk = false;
        // this.hideMessage = false;
        // this.hideMessage2 = true;
        this.logService.insertLocalLog(null, 'Insurance -Order_InsertCommandDirect-error desconocido', data??{});
        this.loadingChange.emit(false);
        this.errorMessage = this.translate.instant("AnErrorOcurredAdmin");
        this.nextEvent.emit(this.errorMessage);
      }
    });
  }

  nextWithoutInsurance(isMobilePay?) {
    this.getGlobalsData();
    this.logService.insertLocalLog(null, 'Insurance - nextWithoutInsurance() "Declinar"', this.globals.Order);
    // this.hideMessage = true;
    // this.hideMessage2 = false;
    this.isDisabled = true;
    this.globals.Order.TotalInsurance = this.globals.tryDouble(0);

    this.globals.executeMethod("Insurance_SelectCommandCalculate", { orderBE: this.globals.Order }, (x) => {
      this.globals.Order = x.Insurance_SelectCommandCalculateResult;
      this.globals.Order.InsuredValue = this.globals.tryDouble(0);
      this.globals.saveData();
      this.segmentService.getGclid(gclid => {
        this.globals.Order.Gclid = gclid;
        this.generar_InsertOrder(isMobilePay);
      });
    });
  }

  onClickGoToSubmitPayment(e) {
    if (this.insuranceAplied == null) {
      e.preventDefault();
    }
  }

  ngOnDestroy() {
    //this.logService.sendLocalLogToServer();
  }

  //enviaActiveCampanign(tempOrden: OrderBE) {
    //let contacto = new ACContactBE;
    //contacto.Email = tempOrden.Customer.Email;
    //contacto.FirstName = tempOrden.Customer.Name;
    //contacto.LastName = tempOrden.Customer.LastName;
    //contacto.ZipCodeUSA = tempOrden.Customer.ZipCode;
    //contacto.Phone = tempOrden.Customer.MobilePhone;
    //this.util.executeMethod('ActiveCampaign_CreateOrUpdateContact', { aCContactBE: contacto }).subscribe((data) => {
      ////console.log(data.ActiveCampaign_CreateOrUpdateContactResult);
      //var TempContacto = {
        //"List": 5,
        //"Contact": parseInt(data.ActiveCampaign_CreateOrUpdateContactResult),
        //"Status": 1
      //}
      //this.util.executeMethod('ActiveCampaign_UpdateListStatusForContact', { aCContactListBE: TempContacto }).subscribe((data2) => {
        ////console.log('paso a data2:');
        ////console.log(data2);
      //},
        //err => {
          //console.log("ERROR Servidor:'" + JSON.stringify(err) + "'")
          //this.logService.insertLocalLog(err, 'calculadora  - Error: ActiveCampaign_UpdateListStatusForContact', ({ aCContactBE: contacto }));
        //});
    //},
      //err => {
        //console.log("ERROR Servidor:'" + JSON.stringify(err) + "'")
        //this.logService.insertLocalLog(err, 'calculadora  - Error: ActiveCampaign_CreateOrUpdateContact', ({ aCContactBE: contacto }));
      //});
    ////console.log(contacto);
  //}
}
