<!-- Faq Section -->
<div id="AboutUs" class="section-block replicable-content"
  style="display:flex; justify-content:center; padding: 20px 0 40px 0;">
  <div class="post-content box large rounded shadow" style="max-width: 750px; width: 90%; background: #f5f7f9;">
    <h2 class="post-title">{{ "Questions" | translate }}<br><span class="weight-semi-bold">{{ "OneCallAway"
        | translate }}</span></h2>
    <p>{{ "Available7Days" | translate }} </p>
    <hr>
    <ul class="list-unstyled list-horizontal list-funding flex left mb-10">
      <li class="amount-pledged center">
        <a href="tel:+18507135098">
          <span class="rounded-circle icon-circled rounded  large border-theme color-theme">
            <i class="fa fa-phone-square" aria-hidden="true" style="font-size: 40px;"></i>
          </span>
        </a>
        <h6 class="mb-0 pt-10 color-theme">{{ "callus" | translate }}</h6>
        <h6 class="mb-0 pt-10">+1 (850) 713-5098</h6>
      </li>
      <li class="amount-funded center">
        <a href="sms:+18507135098">
          <span class="rounded-circle icon-circled rounded  large border-green color-green">
            <i class="fa fa-whatsapp" aria-hidden="true" style="font-size: 40px;"></i>
          </span>
        </a>
        <h6 class="mb-0 pt-10 color-green">SMS</h6>
        <h6 class="mb-0 pt-10">+1 (850) 713-5098</h6>
      </li>
      <li class="days center">
        <a (click)="openTawkChatTs()">
          <span class="rounded-circle icon-circled rounded large border-orange color-orange">
            <i class="fa fa-commenting-o" aria-hidden="true" style="font-size: 40px;"></i>
          </span>
        </a>
        <h6 class="mb-0 pt-10 color-orange">Chat</h6>
        <h6 class="mb-0 pt-10">Support</h6>
      </li>
    </ul>
  </div>
</div>
