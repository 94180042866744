<!--Navbar  -->
<div class="col-12" [style.background-color]="color || '#FFF'">
  <div class="container p-0">
    <nav
      class="md-0 navbar navbar-expand-lg navbar-light"
      style="height: 80px; color: #000"
    >
      <div style="width: 130px; border: none">
        <a href="https://www.bagtotee.com" class="p-0 m-0">
          <img
            [src]="
              color ? './assets/images/logo-dark.svg' : 'assets/images/logo.svg'
            "
            alt="letsbox"
            loading="lazy"
            style="width: 100%; height: 100%"
          />
        </a>
      </div>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent-333"
        style="background: #fff"
        (click)="abrirMenu()"
        aria-controls="navbarSupportedContent-333"
        aria-expanded="false"
        aria-label="Toggle navigation"
        id="botonMenu"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse headerMain"
        id="navbarSupportedContent-333"
        style="background: #000; padding: 5px"
      >
        <ul class="navbar-nav ml-auto nav-flex-icons">
          <li class="nav-item mx-auto my-auto" (click)="clickElemento()">
            <a [routerLink]="['main']" class="btn linkMenu dropdown-item">
              Quote & Ship</a
            >
          </li>
          <li class="nav-item mx-auto my-auto" (click)="clickElemento()">
            <a
              class="btn linkMenu dropdown-item"
              href="https://www.bagtotee.com/learn/shipping-golf-clubs"
            >
              How it works</a
            >
          </li>
          <li class="nav-item mx-auto my-auto" (click)="clickElemento()">
            <a
              class="btn linkMenu dropdown-item"
              (click)="moverseElemento('AboutUs')"
            >
              Customer Support</a
            >
          </li>
          <li class="nav-item mx-auto my-auto" (click)="clickElemento()">
            <a [routerLink]="['Tracking']" class="btn linkMenu dropdown-item">
              {{ "Tracking" | translate }}</a
            >
          </li>
          <li class="nav-item mx-auto my-auto" (click)="clickElemento()">
            <a [routerLink]="['Store']" class="btn linkMenu dropdown-item">
              Store Locator</a
            >
          </li>
          <!--<li class="nav-item mx-auto my-auto" (click)="clickElemento()">-->
          <!--<a class="btn linkMenu dropdown-item" (click)="CambiarIdioma()">-->
          <!--<img *ngIf="lenguajeLocal=='es'" src="./assets/images/flags/1.svg" class="rounded-circle rounded "-->
          <!--alt="es" width="26px" height="26px" loading="lazy" />-->
          <!--<img *ngIf="lenguajeLocal=='en'" src="./assets/images/flags/7.svg" class="rounded-circle rounded "-->
          <!--alt="en" width="26px" height="26px" loading="lazy" />-->
          <!--{{'Language' | translate}}</a>-->
          <!--</li>-->
          <li
            *ngIf="!isLogged"
            class="nav-item mx-auto my-auto"
            (click)="clickElemento()"
          >
            <a [routerLink]="['Login']" class="btn linkMenu dropdown-item">
              <i class="fa fa-lock fa-6"></i> {{ "MyAccount" | translate }}</a
            >
          </li>
          <li *ngIf="isLogged" class="nav-item dropdown btn mx-auto my-auto">
            <a
              class="dropdown-toggle linkMenu dropdown-item"
              id="navbarDropdownMenuLink-4"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-user fa-6"></i> {{ usuario.Name | uppercase }}</a
            >
            <div
              class="dropdown-menu dropdown-menu-right dropdown-info"
              aria-labelledby="navbarDropdownMenuLink-4"
            >
              <a
                [routerLink]="['Profile']"
                class="dropdown-item btn linkMenu"
                (click)="clickElemento()"
              >
                <i class="fa fa-user" aria-hidden="true"></i>
                {{ "Profile" | translate }}
              </a>
              <a
                [routerLink]="['Recent']"
                class="dropdown-item btn linkMenu"
                (click)="clickElemento()"
              >
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                {{ "RecentSendings" | translate }}
              </a>
              <!-- (click)="isShipping()" -->
              <a
                *ngIf="usuario.Role"
                [routerLink]="['CalcShipping']"
                class="dropdown-item btn linkMenu"
                (click)="clickElemento()"
              >
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                {{ "CreateNewSendCustomer" | translate }}
              </a>
              <a class="dropdown-item btn linkMenu" (click)="salir()">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                {{ "Exit" | translate }}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
