<div class="fixed-top menuPrincipal">
  <app-header></app-header>
</div>
<div class="wrapper">
  <div class="wrapper-inner">
    <div class="content clearfix">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
