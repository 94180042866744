<section class="content clearfix accordionMain">
  <div class="column width-12 mb-0"></div>
  <div class="accordion" id="accordionExample">
    <div class="cardAccord">
      <div class="cardAccord__itemFirst">
        <div
          class="cardAccord__header"
          id="question1S"
          data-toggle="collapse"
          data-target="#question1"
          aria-expanded="false"
          aria-controls="question1"
        >
          <p>
            {{ "question1" | translate }}
          </p>
          <span class="icon-down faqIcon" style="color: #000"></span>
        </div>
        <div
          id="question1"
          class="collapse"
          aria-labelledby="question1S"
          data-parent="#accordionExample"
        >
          <div class="cardAccord__item">
            <p>{{ "question1S" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="cardAccord">
        <div
          class="cardAccord__header"
          id="question2S"
          data-toggle="collapse"
          data-target="#question2"
          aria-expanded="false"
          aria-controls="question2"
        >
          <p>
            {{ "question2" | translate }}
          </p>
          <span class="icon-down faqIcon" style="color: #000"></span>
        </div>
        <div
          id="question2"
          class="collapse"
          aria-labelledby="question2S"
          data-parent="#accordionExample"
        >
          <div class="cardAccord__item">
            <p>{{ "question2S" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="cardAccord">
        <div
          class="cardAccord__header"
          id="question3S"
          data-toggle="collapse"
          data-target="#question3"
          aria-expanded="false"
          aria-controls="question3"
        >
          <p>
            {{ "question3" | translate }}
          </p>
          <span class="icon-down faqIcon" style="color: #000"></span>
        </div>
        <div
          id="question3"
          class="collapse"
          aria-labelledby="question3S"
          data-parent="#accordionExample"
        >
          <div class="cardAccord__item">
            <p>{{ "question3S" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="cardAccord">
        <div
          class="cardAccord__header"
          id="question4S"
          data-toggle="collapse"
          data-target="#question4"
          aria-expanded="false"
          aria-controls="question4"
        >
          <p>
            {{ "question4" | translate }}
          </p>
          <span class="icon-down faqIcon" style="color: #000"></span>
        </div>
        <div
          id="question4"
          class="collapse"
          aria-labelledby="question4S"
          data-parent="#accordionExample"
        >
          <div class="cardAccord__item">
            <p>{{ "question4S" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="cardAccord">
        <div
          class="cardAccord__header"
          id="question5S"
          data-toggle="collapse"
          data-target="#question5"
          aria-expanded="false"
          aria-controls="question5"
        >
          <p>
            {{ "question5" | translate }}
          </p>
          <span class="icon-down faqIcon" style="color: #000"></span>
        </div>
        <div
          id="question5"
          class="collapse"
          aria-labelledby="question5S"
          data-parent="#accordionExample"
        >
          <div class="cardAccord__item">
            <p>{{ "question5S" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="cardAccord">
        <div
          class="cardAccord__header"
          id="question6S"
          data-toggle="collapse"
          data-target="#question6"
          aria-expanded="false"
          aria-controls="question6"
        >
          <p>
            {{ "question6" | translate }}
          </p>
          <span class="icon-down faqIcon" style="color: #000"></span>
        </div>
        <div
          id="question6"
          class="collapse"
          aria-labelledby="question6S"
          data-parent="#accordionExample"
        >
          <div class="cardAccord__item">
            <p>{{ "question6S" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="cardAccord cardAccord__itemLast">
        <div
          class="cardAccord__header"
          id="question7S"
          data-toggle="collapse"
          data-target="#question7"
          aria-expanded="false"
          aria-controls="question7"
        >
          <p>
            {{ "question7" | translate }}
          </p>
          <span class="icon-down faqIcon" style="color: #000"></span>
        </div>
        <div
          id="question7"
          class="collapse"
          aria-labelledby="question7S"
          data-parent="#accordionExample"
        >
          <div class="cardAccord__item">
            <p>{{ "question7S" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
