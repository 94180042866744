import { Component, ElementRef, Input } from '@angular/core';
import { Globals } from '../../Entities/Globals';
import { CustomerBE } from '../../Entities/CustomerBE';
import { Router } from '@angular/router';
import { VersionService } from '../../services/version.service';
import animateScrollTo from 'animated-scroll-to';
import { LanguageService } from '../../services/language.service';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs';
import { CountriesService } from '../../services/countries.service';
import { ComplemtService } from '../../services/complemt.service';
import { CountryBE } from '../../Entities/CountryBE';
import { LogService } from '../../services/log.service';
import { OrderBE } from '../../Entities/OrderBE';
import { OrdenService } from '../../services/orden.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
declare function openMenu(): any;
declare var findPosition:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls : ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isSpecialHeader : any;
  version: string;
  iteraciones: number = 0;
  isLogged: boolean = false;
  bandDeplegado: boolean = false;
  lenguajeLocal: string = '';
  usuario: CustomerBE = new CustomerBE();
  escuchaUser$: Observable<CustomerBE>;
  miIP: any;

  @Input() color? : string = "#000";

  constructor(
    private languageService: LanguageService,
    private globals: Globals,
    private router: Router,
    private logService: LogService, //SERVICIO DE REGISTRO DE LOG
    private elementRef: ElementRef,
    private authService: AuthService,
    private countriesService: CountriesService,
    private ordenService: OrdenService,
    public util: ComplemtService,
    public httpClient: HttpClient,
    private vs: VersionService,
    private config: AppConfig
  ) {
    //
    //this.CargarUser();

    //Cargo el idioma
    this.lenguajeLocal = languageService.getLenguaje();

    if (this.authService.isLogin) {
      this.usuario = JSON.parse(this.authService.getInfoUser());
      this.isLogged = true;
      //this.seccion = JSON.parse(this.authService.getInfoSeccion());
      // this.fecha=this.seccion.fechaBD;
    }

    // this.util.executeMethod('TarifasTE_SelectCommand',{}).subscribe((data) => { },
    // err => {
    //   console.log("ERROR Servidor:'"+JSON.stringify(err)+"'");
    // });

    if (this.logService.isLog) {
      this.logService.sendLocalLogToServer();
    }

    if (!this.countriesService.isCountryBE) {
      let objCountry = new CountryBE();
      objCountry.IsEnabled = true;
      let contador = 0;
      //this.logService.insertLocalLog('prueba4', 'Header - Constructor  - Activado los log' , null);
      this.util
        .executeMethod('Country_SelectCommand', { countryBe: objCountry })
        .subscribe(
          (data) => {
            this.countriesService.setInfoCountryBE(
              JSON.stringify(data.Country_SelectCommandResult)
            );
            this.countriesService.setIsCountryBE(true);
            console.log('Se cargo la lista de paises ok-01');
            // this.logService.insertLocalLog(
            //   'Se cargo la lista de paises ok-01',
            //   'Header - Constructor  - Control: City_SelectCommand',
            //   null
            // );
            //console.log(data.Country_SelectCommandResult);
            //     data.Country_SelectCommandResult.forEach(element => {
            //   let obj = { cityBE: { CountryBE: { Id: element.Id  }, }  };
            //   // console.log("Se va a cargar la lista de pais"+element.Id+" ok-02");
            //   //   this.logService.insertLocalLog("Se cargo la lista de pais"+element.Id+" ok-02", 'Header - Constructor  - Control: City_SelectCommand' , ({ ListacountryBe: data.Country_SelectCommandResult }));

            //   this.util.executeMethod('City_SelectCommand', obj).subscribe(  (data2) => {
            //     let CityBEResult:CityBE[]=[];
            //     CityBEResult=data2.City_SelectCommandResult;
            //     CityBEResult.forEach(elem=>{
            //       elem.CountryBE=element;
            //     })
            //     this.countriesService.setInfoCityBE(element.Name,JSON.stringify(CityBEResult));
            //     //lo manda actualizar en la ultima lista cargada
            //     if (contador==(data.Country_SelectCommandResult.length-1)){
            //       this.countriesService.CountryBEPrincipal$.next(new CountryBE());
            //     }else {contador++;}
            //     console.log("Se cargo la lista de pais"+element.Id+" ok-02");
            //     this.logService.insertLocalLog("Se cargo la lista de pais"+element.Id+" ok-02", 'Header - Constructor  - Control: City_SelectCommand' ,  null);
            //   },
            //   err => {
            //     console.log("ERROR Servidor:'"+JSON.stringify(err)+"'")
            //     this.logService.insertLocalLog(err, 'Header - Constructor  - Error: City_SelectCommand' , ({ BusquedacountryBe: element }));
            //   });
            // });
            // tempelementid=data.Country_SelectCommandResult.Id
          },
          (err) => {
            console.log("ERROR Servidor:'" + JSON.stringify(err) + "'");
            this.logService.insertLocalLog(
              err,
              'Header - Constructor  - Error: Country_SelectCommand',
              { countryBe: objCountry }
            );
          }
        );
    } else {
      // console.log("los paises ya estan cargados");
      // console.log(JSON.parse(this.countriesService.getInfoCountryBE()));
      // console.log(JSON.parse(this.countriesService.getInfoCityBE("MEXICO")));
      // console.log(JSON.parse(this.countriesService.getInfoCityBE("VENEZUELA")));
      // console.log(JSON.parse(this.countriesService.getInfoCityBE("COLOMBIA")));
    }
    // translate.setDefaultLang('es');
    //this.globals.Order.Language.Code = this.lenguajeLocal;
    // this.version = vs.getVersion();
    // if(this.globals.Order.Language === undefined || this.globals.Order.Language === null){
    //     this.globals.Order.Language = new LanguageBE();
    //     this.globals.saveData();
    // }
    // if ( this.globals.Order.Customer != null && this.globals.Order.Customer.IsLogged) {
    //     this.isLogged = true;
    // } else {
    //     this.isLogged = false;
    // }
  }

  CargarUser() {
    // this.httpClient.get('http://api.ipify.org/?format=json').subscribe(
    //   (response: any) => {
    //     //console.log("response",response);
    //   },
    //   (error: any) => {
    //     console.log('error', error);
    //   }
    // );
  }

  ngOnInit(): void {
    this.escuchaUser$ = this.authService.getCustomer$();
    this.escuchaUser$.subscribe((res) => {
      this.usuario = JSON.parse(this.authService.getInfoUser());
      this.isLogged = this.authService.isLogin;
    });
    // // carga el javascript
    // var s = document.createElement("script");
    // s.type = "text/javascript";
    // s.src = "./assets/js/timber.master.min.js";
    // this.elementRef.nativeElement.appendChild(s);
  }

  salir() {
    this.clickElemento();
    this.authService.loginOUT();
    this.router.navigate(['Login']);
    this.globals.Order = new OrderBE();
    this.globals.saveData();
  }

  goMain() {
    if (this.router.url != '/Main') {
      this.router.navigate(['Main']);
    }
  }

  switchLanguage(language: string) {
    // this.globals.Order.Language.Code = language;
    // this.translate.use(language);
    // this.vs.setLocalLanguage(language);
  }

  LogOut() {
    // this.isLogged = false;
    // this.globals.Order.Customer = new CustomerBE();
    // this.router.navigate(['./Login']);
    // this.globals.saveData();
  }

  sendEvent() {
    // (<any>window).ga('send', 'event', {
    //     eventCategory: 'eventCategory',
    //     eventLabel: 'eventLabel',
    //     eventAction: 'eventAction',
    //     eventValue: 10
    //   });
  }

  openMenuTs() {
    openMenu();
  }



  clickElemento() {
    if (this.bandDeplegado) {
      document.getElementById('botonMenu').click();
    }
  }

  moverseElemento(elemento) {
    let pos = findPosition(document.getElementById(elemento));
    if (pos >= 0) {
      if (pos > 80) {
        pos = pos - 40;
      }
      animateScrollTo(pos);
    } else {
      if (this.router.url != '/Main') {
        this.router.navigate(['Main']);
        setTimeout(function () {
          pos = findPosition(document.getElementById(elemento));
          if (pos >= 0) {
            pos = pos + 40;
            animateScrollTo(pos);
          } else {
            console.log(
              'ERROR: No se encontro el elemento en la pagina :' + elemento
            );
          }
        }, 250);
      } else
        console.log(
          'ERROR: No se encontro el elemento en la pagina :' + elemento
        );
    }
  }

  CambiarIdioma() {
    if (this.lenguajeLocal == 'es') {
      this.lenguajeLocal = 'en';
    } else {
      this.lenguajeLocal = 'es';
    }
    this.languageService.setLenguaje(this.lenguajeLocal);
    this.languageService.Slenguaje$.next(this.lenguajeLocal);
    //this.globals.Order.Language.Code = language;
    //this.vs.setLocalLanguage(language);
  }

  abrirMenu() {
    this.bandDeplegado
      ? (this.bandDeplegado = false)
      : (this.bandDeplegado = true);
  }

  isShipping() {
    this.clickElemento();
  }
}
