<!-- <app-head-mobile></app-head-mobile> -->
<div class="wrapper reveal-side-navigation">
  <div class="wrapper-inner">
    <!-- <app-header></app-header> -->
    <!-- Content -->
    <div class="content clearfix">
      <div class="col-12 MostrarMovil" style="height: 80px; ">
      </div>
      <!-- Intro Section hero-2-6-->
      <div id="home" class="section-block fondoLogin clear-height-on-tablet no-padding-top-responsive">
        <!--div class="media-overlay bkg-black opacity-05"></div-->
        <div class="lxbrow flex v-align-middle">
          <div class="column width-12">
            <div class="lxbrow flex one-column-on-tablet">
              <div class="column width-3 v-align-middle">


              </div>
              <div [ngClass]="{'column width-12': isDevice,'column width-8 offset-1': !isDevice}">
                <!--div class="signup-box box rounded xlarge mb-0 bkg-white shadow" data-animate-in="preset:slideInLeftShort;duration:1000ms;delay:0;"-->
                <!-- <my-placeholder name="main"></my-placeholder> -->
                <!--/div-->
                <router-outlet name="master"></router-outlet>
              </div>

              <!--input type="submit" *ngIf="isDevice" value="{{'Home' | translate}}" (click)="goToFirstScreen()" class="button rounded large text-small bkg-theme bkg-hover-theme color-white color-hover-white btnStyleMobile"-->
            </div>
          </div>
        </div>
      </div>
      <!-- Intro Section End -->
      <!-- YouTube Video -->
      <!--div id="video" class="section-block pt-80 pb-0" [hidden]="!this.globals.isFirstScreen && isDevice">
                <div class="lxbrow">
                    <div class="column width-10 offset-1 center">
                        <h3 class="mb-50">{{ 'LetsBoxTheBestWay' | translate}}</h3>
                        <div class="video-container clVideo">
                            <iframe id="iVideoExplain" width="500" height="517"></iframe>
                        </div>
                    </div>
                </div>
            </div-->
      <!--div id="video" class="section-block pt-80 pb-0">
                <div class="lxbrow">
                    <div class="column width-10 offset-1 center">
                        <h3 class="mb-50">{{ 'LetsBoxTheBestWay' | translate}}</h3>
                        <div class="video-container" data-pause-on-scroll>
                            <iframe src="https://player.vimeo.com/video/315544701?title=0&byline=0&portrait=0" width="500" height="281"  ></iframe>
                        </div>
                    </div>
                </div>
            </div-->
    </div>
    <!-- Content End -->
    <app-footer></app-footer>

  </div>
</div>
