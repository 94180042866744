import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { Globals } from '../../Entities/Globals';
import { PoolService } from '../../services/pool.service';

@Component({
  selector: 'app-payment-element',
  templateUrl: './payment-element.component.html',
  styleUrls: ['./payment-element.component.css']
})
export class PaymentElementComponent implements OnInit, OnChanges {
  @Input() clientSecret: string = "";
  @Input() nameUser: string = "";
  @Input() email: string = "";
  @Output() loading = new EventEmitter<boolean>();
  @Output() errorMessage = new EventEmitter<string>();

  public isValid: boolean = false;
  private stripe: Stripe | null = null;
  private elements: StripeElements | null = null;

  constructor(
    private poolservice: PoolService,
    private global: Globals
  ) { }

  getUrlConfirmation(): string {
    return this.global.UrlWeb + "/" + this.poolservice.ID_ORDER;
  }

  async ngOnInit() {
    this.stripe = await loadStripe(this.global.TokenPublic);
  }

  ngOnChanges() {
    this.actualizar();
  }

  actualizar() {
    if (this.stripe && this.clientSecret) {
      this.isValid = true;
      const appearance = {};
      const options = {};
      this.elements = this.stripe.elements({ clientSecret: this.clientSecret, appearance });
      const paymentElement = this.elements.create('payment', options);
      paymentElement.mount('#payment-element');
    }
  }

  async handlePayment() {

    if (!this.stripe || !this.elements) {
      return;
    }

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: this.nameUser,
            email: this.email,
          },
        },
        return_url: this.getUrlConfirmation(), // URL a la que redirigir después del pago
      },
    });

    if (error) {
      // Muestra el mensaje de error
      const errorMessage = error.message || 'Ocurrió un error durante el pago.';
      this.errorMessage.emit(errorMessage);
    }
  }
}