import { Injectable } from '@angular/core';
import { AngularLogBE } from '../Entities/AngularLogBE';
import { AngularLogListBE } from '../Entities/AngularLogListBE';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { IpService } from './ip.service';
import { OrderBE } from '../Entities/OrderBE';


@Injectable({
  providedIn: 'root'
})
export class LogService {
  
  UrlApi: string;
  registros: AngularLogBE[]=[];
  nameLocalStore: string='Logs';

  constructor(private http: HttpClient, private config: AppConfig, private ip: IpService) {
    this.UrlApi = this.config.GetConfig('UrlApi');
    try{
      if (this.registros.length==0) this.registros= JSON.parse(localStorage.getItem(this.nameLocalStore))??[];
    }catch{}    
  }

  get isLog() {
    if (sessionStorage.getItem('islog') == 'true') {
      return true;
    } else {
       return false; 
      }
  }

  public addRegistro(res:AngularLogBE){
    this.registros.push(res);
    this.saveRegistros();
  }

  public insertLog(exception, methodName, json){
    let angularLogBE = new AngularLogBE();
    exception = (JSON.stringify(exception)).replace(/"/g, "'").slice(0,2000);
    angularLogBE.ngException = exception;
    angularLogBE.MethodName = methodName;
    angularLogBE.Order = JSON.stringify(json);
    this.addRegistro(angularLogBE);
  }

  saveRegistros() {
    localStorage.setItem(this.nameLocalStore, JSON.stringify(this.registros));
  }

  sendRegistros(client:string){
    if (this.registros.length==0 || client =="") return;
    let localLogList = new AngularLogListBE();
    localLogList.registros = this.registros;
    localLogList.clientIP = client;
    this.resetLog();
    this.executeMethod("AngularLog_InsertCommand", { angularLogListBE: localLogList }, x => { });
  }

  setIsLog(estado: boolean) {
    if (estado) {
      sessionStorage.setItem('islog', 'true');
    } else sessionStorage.setItem('islog', 'false');
  }

  resetLog(){
    this.registros=[];
    this.saveRegistros();
  }

  public checkLocalLog() {
    let localLog = this.getLocalLog();

    if (!localLog) {
      this.resetLocalLog();
    }
  }

  private getLocalLog() {
    let localLog = JSON.parse(localStorage.getItem('localLog'));
    return localLog;
  }

  private setLocalLog(json) {
    json = JSON.stringify(json);
    localStorage.setItem('localLog', json);
    this.setIsLog(true);
  }

  public resetLocalLog() {
    let json = JSON.stringify({ registros: [] });
    localStorage.setItem('localLog', json);
    this.setIsLog(false);
  }
  //

  public insertLocalLog(exception, methodName, json, enviar=true) {
    let localLog= this.isLog?this.getLocalLog():[];
    let angularLogBE = new AngularLogBE();
    /* codigo a revisar*/
    exception = (JSON.stringify(exception)).replace(/"/g, "'");
    exception.length < 30000 ? exception : exception = "length overflow";

    angularLogBE.ngException = exception;
    angularLogBE.MethodName = methodName;
    //angularLogBE.Order=;
    angularLogBE.Order = JSON.stringify(json);
    // console.log("json",json);
    // console.log("localLog",localLog);
    
    localLog.push(angularLogBE);
    this.setLocalLog(localLog);
    if (enviar){
      this.sendLocalLogToServer();
    }
  }

  public sendLocalLogToServer() {
    try {

      let localLog= this.isLog?this.getLocalLog():[];
     // console.log(this.isLog);
      //console.log(localLog);
    if (this.isLog){

    //if (localLog != null && localLog.registros.length > 0) {
      //let tempLogList = [];

      // codigo a revisar
      //  localLog.forEach(element => {
      //    let temp
      // //   element.Order.Gclid = localStorage.getItem('gclid');
      // //   element.Order.Navigator = navigator.userAgent;
      // //   let localLogItem = new AngularLogBE();
      // //   localLogItem = element;
      //     tempLogList.push(localLogItem);
      //  });
       let localLogList = new AngularLogListBE();
      // localLogList.tempLogList.
       localLogList.registros = localLog;
       localLogList.clientIP = this.ip.getLocalClientIP();

      let tempLocalLogList = localLogList;
      
      //console.log(localLogList);
      this.resetLocalLog();
      this.executeMethod("AngularLog_InsertCommand", { angularLogListBE: localLogList }, x => {
        //this.resetLocalLog();
        //tempLocalLogList = null;
      });
    }

  }
  catch (exception) {
    console.log(exception)
  }

  }

  //Global methods

  private executeMethod(nameMethod, objData, returnData) {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    this.http.post(this.UrlApi + nameMethod, JSON.stringify(objData), { headers: headers })
      .subscribe(data => {

        returnData(data);
      },
        err => {
          this.insertLocalLog(err, 'logService - AngularLog_InsertCommand', objData, false);
        }
      );
  }
  public consolelog(var1){
    
  }

}
