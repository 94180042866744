import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class PoolService {
    private  id_order: string = '0';
    private  id_order_key: string = 'sku';

    public  get ID_ORDER(): string {
        if (this.id_order==null||this.id_order==undefined||this.id_order=='0') {
            this.id_order = localStorage.getItem(this.id_order_key);
        }
        return this.id_order ? this.id_order : '0';
    }

    public  set ID_ORDER(value: string) {
        localStorage.setItem(this.id_order_key, value);
        this.id_order = value;
    }
}