import { Injectable } from '@angular/core';
import { OrderBE } from './OrderBE';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CustomerBE } from './CustomerBE';
import { Router } from '@angular/router';
import { PackageOrderBE } from './PackageOrderBE';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AppConfig } from '../../app.config';
import { LogService } from '../services/log.service';
import { LocalService } from '../services/local.service';

@Injectable()
export class Globals {
  // DECLARACION DE vARIABLES gLOBALES
  UrlFiles: string = "";
  UrlApi: string = "";
  UrlWeb: string = "";
  TokenPublic: string = "";
  LstCountry: any = [];
  FBUrlApiGraph: string = "";
  FBAppId: string = "";
  FBAppIdVer: string = "";
  FBAccesToken: string = "";
  IpInfoDBHost: string = "";
  IpInfoDBKey: string = "";
  PreviousUrl: string = "";

  isCheckDocuments: boolean;
  isDevice: boolean = false;
  public url: string;
  now: string;

  Customer = new CustomerBE();

  public Order:OrderBE = new OrderBE();
  public paymentMethod: String = ""

  constructor(private http: HttpClient,
    private router: Router,
    private config: AppConfig, //ARCHIVO CONFIGURACION APP
    private logService: LogService, //SERVICIO DE REGISTRO DE LOG
    private localService: LocalService //SERVICIO DE ALMACENAMIENTO LOCAL
  ) {
    //vARIABLES DE CONFIGURACION gLOBALES
    this.config = config;
    this.UrlApi = this.config.GetConfig('UrlApi');
    this.UrlWeb = this.config.GetConfig('UrlWeb');
    this.TokenPublic = this.config.GetConfig('TokenPublic');
    this.UrlFiles = this.config.GetConfig('UrlFiles');
    this.FBUrlApiGraph = this.config.GetConfig('FacebookAPIGraphHost');
    this.FBAppId = this.config.GetConfig('LetsboxitAPPId');
    this.FBAppIdVer = this.config.GetConfig('LetsboxitAPPIdVer');
    this.FBAccesToken = this.config.GetConfig('LetsboxitAppAccesToken');
    this.IpInfoDBHost = this.config.GetConfig('IpInfoDBHost');
    this.IpInfoDBKey = this.config.GetConfig('IpInfoDBKey');
    this.LstCountry = this.config.GetConfig('lstCountry');

    //limpiar Cache
    this.limpiarCache();

    //Initialize LocalStorage
    if (localStorage.getItem("IsDevice" + this.FBAppIdVer) == null) {
      this.saveDevice();
    }
    if (localStorage.getItem("Order" + this.FBAppIdVer) == null) {
      this.localService.setJsonValue("Order" + this.FBAppIdVer, JSON.stringify(new OrderBE()));
    }
    if (localStorage.getItem("Time" + this.FBAppIdVer) == null) {
      this.localService.setJsonValue("Time" + this.FBAppIdVer, new Date().getTime().toString());
    }
  }

  public limpiarCache() {
    let id;
    if ((id = localStorage.getItem("IdVer")) != null) {
      if (id != this.FBAppIdVer) {
        console.log('//limpio el cache ver: ', id);
        localStorage.removeItem("IsDevice" + id);
        localStorage.removeItem("Order" + id);
        localStorage.removeItem("Time" + id);
        localStorage.removeItem('isCountryBE' + id);
        localStorage.removeItem("CountryBE" + id);
        sessionStorage.removeItem("Order" + id);
        localStorage.setItem("IdVer", this.FBAppIdVer);
      }
    } else {
      console.log("//Se creo desde cero :", this.FBAppIdVer);
      localStorage.setItem("IdVer", this.FBAppIdVer);
    }
  }

  public tryDouble(value) {
    if (isNaN(value)) {
      return 0;
    }
    return value;
  }

  public saveData() {
    this.VerificateSesion();
    this.localService.setJsonValue("Order" + this.FBAppIdVer, JSON.stringify(this.Order));
    this.localService.setJsonValue("Time" + this.FBAppIdVer, new Date().getTime().toString());
  }

  public saveDevice() {
    this.localService.setJsonValue('IsDevice', (this.isDevice));
  }


  public getDataDevice() {
    if (this.localService.getJsonValue('IsDevice') === null || this.localService.getJsonValue('IsDevice') === "") {
      this.isDevice = false;
    } else {
      this.isDevice = (this.localService.getJsonValue('IsDevice'));
    }
  }

  public getData() {
    this.VerificateSesion();
    if (this.localService.getJsonValue("Order" + this.FBAppIdVer) === null || this.localService.getJsonValue("Order" + this.FBAppIdVer) === "") {
      this.Order = new OrderBE();
      this.saveData();
    }
    this.Order = JSON.parse(this.localService.getJsonValue("Order" + this.FBAppIdVer));//this.localService.getJsonValue('Order')
    this.Order.isCheckDocuments = this.isCheckDocuments;
    return this.Order;
  }

  public VerificateSesion() {
    this.localService.setJsonValue("Time" + this.FBAppIdVer, new Date().getTime().toString());
    if (this.localService.getJsonValue("Time" + this.FBAppIdVer) != undefined) {
      var oldDate: Date;
      oldDate = new Date(Number(this.localService.getJsonValue("Time" + this.FBAppIdVer)));
      oldDate.setHours(oldDate.getHours() + 2);
      if (new Date() > oldDate) {
        this.Order.Customer = new CustomerBE();
        this.router.navigate(['./Main']);
        localStorage.removeItem("Time" + this.FBAppIdVer)
        this.saveData();
      }
    }

  }

  public VerificateData() {
    this.getData();
    if (!this.Order.Customer.IsLogged) {
      if (this.Order.PaymentMethodTotal != "CC") {
        this.router.navigate(['./Login']);
      }
    }
    return this.Order;
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof UntypedFormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }


  public CheckCalculate() {
    if (this.Order.CalculateResponse === undefined || this.Order.CalculateResponse.CalculateResult != 1) {
      this.router.navigate(['./Main']);
    }
  }
  public resetGeneralData() {
    this.Order.Packages = new Array<PackageOrderBE>();
    let copy = JSON.parse(JSON.stringify(this.Order.Customer));
    let copyOrder = JSON.parse(JSON.stringify(this.Order));
    this.Customer = copy;
    this.Order = new OrderBE();
    this.Order.Customer = this.Customer;
    this.Order.isCustomerShipping = copyOrder.isCustomerShipping;
    this.saveData();
  }

  public Round(num, decimals) {
    var t = Math.pow(10, decimals);
    return (Math.round((num * t) + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / Math.pow(100, decimals)))) / t).toFixed(decimals);
  }

  public validateRoute(actRoute, route) {

    let shipmentType;

    actRoute.paramMap.subscribe(params => {
      shipmentType = params.get('ShipmentType');

      if (shipmentType == 'CustomerShipping' && this.Order.Customer.Role == 1) {
        this.Order.isCustomerShipping = true;
      } else {
        this.Order.isCustomerShipping = false;
        this.router.navigate(['./' + route]);
      }
    });
    return shipmentType;
  }

  getActualRoute() {
    let route = this.router.url;
    route = route.split('/')[1];
    return route;
  }

  public executeMethod(nameMethod, objData, returnData) {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    this.http.post(this.UrlApi + nameMethod, JSON.stringify(objData), { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);
        returnData(data);
      },
        err => {
          console.log(err)
          this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }
  /*
    public getFacebookReviews(returnData) {
  
      let Url = `${this.FBUrlApiGraph}/v6.0/${this.FBAppId}/ratings?fields=open_graph_story&access_token=${this.FBAccesToken}`;
  
      this.http.get(Url)
        .subscribe(data => {
          returnData(data);
        },
          err => {
            console.log(err)
          }
        );
    }
  
    public getFacebookUserIdFromGraphHistory(graphHistoryId, returnData) {
  
      let request = new OrderBE();
      request.CustomResponse.ResultCode = graphHistoryId;
  
      this.executeMethod("RequestFacebookUserIdFromGraphHistory", { customResponseBE: request.CustomResponse }, data => {
        let res = data.RequestFacebookUserIdFromGraphHistoryResult.ResultCode
        returnData(res);
      })
    }
  
    public getFacebookProfilePicture(userId, returnData) {
      let Url = `https://graph.facebook.com/${userId}/picture`;
  
      this.http.get(Url)
        .subscribe(data => {
  
        },
          err => {
            returnData(err.url)
          }
        );
    }
  */
  public getClientLocation(ip, returnData) {
    try {
      let Url = `${this.IpInfoDBHost}/?key=${this.IpInfoDBKey}&ip=${ip}&format=json`;

      this.http.get(Url)
        .subscribe(data => {
          returnData(data);
        },
          err => {
            console.log(err)
          }
        );
    } catch (erro) {
      console.log(erro)
    }
  }
}
