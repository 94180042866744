import { Component } from '@angular/core';
import { Globals } from '../../../Entities/Globals';
import { ComplemtService } from '../../../services/complemt.service';
import { Observable } from 'rxjs';
import { PackageBE } from '../../../Entities/PackageBE';
import { CountriesService } from '../../../services/countries.service';
import { CountryBE } from '../../../Entities/CountryBE';

@Component({
  selector: 'app-summaryExtend3-Prev',
  styleUrls: ['./summaryExtend3Prev.component.scss'],
  templateUrl: './summaryExtend3Prev.component.html',
})
export class SummaryExtend3Prev {
  globals: any;
  codISO2 = '';

  country: string;
  Code: string = '';

  sizes : any;

  zipOrigination$: Observable<any>;
  zipDestination$: Observable<any>;

  constructor(
    private global: Globals,
    public util: ComplemtService,
    private countryService: CountriesService,
  ) {
    this.init();
    this.getIsoCountry();
  }

  getZipCodes() {
    const zipCodes: PackageBE = this.globals.Order.Package;
    this.zipOrigination$ = this.util.executeMethod('Order_Zipcode', {
      codigo: zipCodes['ZipOrigination'],
    });
    this.zipDestination$ = this.util.executeMethod('Order_Zipcode', {
      codigo: zipCodes['ZipDestination'],
    });
  }

  private getIsoCountry() {
    this.country = JSON.parse(this.countryService.getInfoCountryBE()).find(
      (value: CountryBE) => this.codISO2 == value['ISO2'],
    );
  }

   formatDate(date: any): string {
    if (!date) return;
    const newDate = new Date(
      parseInt(date.replace(/\/Date\((.*?)[+-]\d+\)\//i, '$1'), 10),
    );
    return newDate.toLocaleDateString('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    });
  }

  init() {
    let obj = Object.assign({}, this.global);
    this.globals = obj;
    this.sizes = this.globals.Order.Packages[0];
  }

  getGlobalsData() {
    this.global.getData();
    this.init();
  }
}
