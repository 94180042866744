<div
  class="column width-12 mb-0"
  style="text-align: justify; font-size: 16px;"
  [ngClass]="{ contenidoModal: modal }"
>
  <p>
    Privacy Notice Intro: This privacy policy from BagToTee (hereinafter
    referred to as “BagToTee”, “we”, “us”, “our”) has been compiled to better
    serve those who are concerned about how their "Personally Identifiable
    Information" (PII) is being used online (this also includes our mobile
    applications). PII, as used in the US Information Privacy and Security Act,
    is information that can be used on its own or with other information to
    identify, contact, or locate a single person, or to identify an individual
    in context. Please read our privacy policy carefully to fully understand how
    we collect, use, protect, or otherwise handle your Personally Identifiable
    Information in accordance with our website. It also describes the choices
    you have concerning the use, access, and correction of your personal
    information. If you have an unresolved concern about information use or
    privacy that we haven't addressed satisfactorily, write to us at
    support@bagtotee.com. What personal information do we collect from people
    who visit our website or app? We might collect the following information
    about you, which might include non-public information: When placing a
    request or registering on our site, you might be asked to enter your full
    name, gender, age, email address, postal address, phone number, date of
    birth, bank account number, and credit or debit card information. This
    information is needed to process your transaction and to assist with your
    experience. To facilitate the service, we ask for certain third-party
    personal information from you or your mobile device's address book, such as
    the full name of your recipient, physical address, email address, and phone
    number. Information about your use of the Service, including your
    transaction history, how you use the service to send packages, and to whom
    you send packages; Information we legally obtain from third parties, such as
    identity verification services, electronic databases, and credit reporting
    agencies; Information indirectly obtained from you, like information about
    the device, hardware, and software you use to access the Service, your IP
    address, pages you access on this and other websites you visited before
    accessing the Service; Records of your communications with our Customer
    Service department, including phone calls and electronic communications; Any
    additional personal information you provide to bagtotee online, by email, or
    by phone. We may receive information about you from other sources, including
    publicly available databases or third parties from whom we have acquired
    information, and combine this data with the information we already have
    about you. This helps us update, expand and analyze our records, identify
    new customers, and offer products and services that may be of interest to
    you. If you provide us with personal information about others, or if others
    give us your information, we will only use it for the specific reasons for
    which it was provided. Some examples of the types of personal information
    that can be obtained from public sources or purchased from third parties and
    combined with the information we already have about you include: You might
    provide us with personal information about others, such as recipients' names
    and identifiers, address, phone number, bank account data, or email address.
    In such cases, you confirm you have the proper authority to do so, and we
    are allowed to use the information, and we will use it only for the specific
    reason it was given to us. If you believe one of your contacts has provided
    us with your personal information and you'd like to request its removal from
    our database, please contact us here. When do we collect information? We
    collect information about you when you register on our site, place an order,
    or enter information on our site online or through Customer Service. How do
    we share and use your information? We will share your personal information
    with third parties only in the ways described in this privacy policy. We
    might provide your personal information to companies that provide services
    to help us with our business activities, such as fund settlement. These
    companies are authorized to use your personal information only as necessary
    to provide us with these services. We might use the information we obtain
    about you when you register, make a purchase, subscribe to our newsletter,
    respond to a survey or marketing communication, surf the web, or use other
    site features in the following ways: To provide you with the service you've
    requested, ordered, and demanded from Bagtotee through our website
    www.bagtotee.com; To fulfill your order, send order confirmation, and manage
    our customer service communications with you; To contact you over the phone
    by sending automated or pre-recorded calls or text messages (SMS) (if
    applicable) as authorized for the purposes described in this Privacy Policy;
    To quickly process your transactions; To verify your identity; To charge for
    your use of the service; To allow our partners to fulfill their obligations
    to you; To troubleshoot problems with the service; To comply with current
    laws, regulations, and standards, such as those related to "know your
    customer" and anti-money laundering requirements; To detect and prevent
    fraud and other illegal uses of the service; To help us further improve and
    develop our products and services; To determine if you arrived at our site
    from an online advertisement or an affiliate website; To determine and
    establish effective promotions and marketing campaigns; To provide you with
    information about BagToTee products and services that are most relevant to
    you; To continuously improve our website and customer experience on
    www.bagtotee.com. You can choose to stop receiving our newsletters or
    promotional emails by following the unsubscribe instructions included in
    these emails, accessing the email preferences in the settings page of your
    account, or you can contact us at support@ BagToTee We may also disclose
    your personal information as required by law, such as responding to legal
    requests from public authorities, which includes meeting national security
    or law enforcement requirements, complying with a subpoena, bankruptcy
    proceedings, or similar legal processes when we believe in good faith that
    disclosure is necessary to protect our rights, protect your safety or the
    safety of others, investigate fraud, or respond to a government request. If
    BagToTee is involved in a merger, acquisition, or sale of all or a portion
    of its assets, you will be notified via email or a prominent notice on our
    website about any change in ownership or uses of your personal information,
    as well as any choices you may have regarding your personal information. We
    may also disclose your personal information to third parties with your prior
    consent. How can customers access their account? Upon request, BagToTee will
    inform you if we hold any personal information about you. If you wish to
    request access, delete or update your personal information, contact us at
    support@ BagToTee.com. We will respond to your request within a reasonable
    timeframe. We will retain your information for as long as your account is
    active or as needed to provide you with services. We will retain and use
    your information as necessary to comply with our legal obligations, resolve
    disputes, and enforce our agreements. How do we protect visitor information?
    Our website is frequently scanned for known security vulnerabilities to make
    your visit to our site as safe as possible. We implement a variety of
    reasonable security measures when a user enters, submits, or accesses their
    information. However, no method of transmission over the Internet or
    electronic storage method is 100% secure. Therefore, we cannot guarantee its
    absolute security. If you have any questions about the security of your
    personal information, you can contact us at support@ BagToTee.com. Do we use
    "cookies" and other tracking technologies? We, affiliates, or our analytics
    or service providers, use technologies like cookies, beacons, tags, scripts,
    or similar technologies to analyze trends, administer the website, track
    users' movements around the website, and gather demographic information
    about our user base as a whole. We use cookies for the following purposes:
    To help remember and process your transactions. To understand and save user
    preferences for future visits. To gather aggregated data about site traffic
    and interaction in order to offer better site tools and experiences in the
    future. We might also use trusted third-party services to track this
    information on our behalf. You can choose to have your computer warn you
    each time a cookie is being sent, or you can choose to turn off all cookies
    through your browser settings. Each browser is different, so you might need
    to check your browser's Help menu to learn how to modify cookies. If you
    disable cookies, you won't be able to complete any transactions. Like most
    websites, we gather certain information automatically and store it in log
    files. This information might include internet protocol (IP) addresses,
    browser type, internet service provider (ISP), referring/exit pages,
    operating system, date/time stamp, and clickstream data. We link this
    automatically-collected data to other information we might collect about
    you. We partner with a third party to display advertising on our website or
    to manage our advertising on other sites. Our third-party service partner
    might use technologies like cookies to gather information about your
    activities on this website and other sites to provide you with advertising
    based on your browsing activities and interests. If you wish to not receive
    interest-based advertising, click here. Mobile Application Upon downloading
    and using our services, we automatically gather information about the type
    of device you use, version of the operating system, and system and
    performance information. We occasionally send you push notifications to keep
    you informed about events and promotions we might offer. If you no longer
    wish to receive such notifications, you can deactivate them at the device
    level. To ensure you receive appropriate notifications, we might need to
    gather certain information about your device, such as the operating system
    and user identification information. We gather your location-based
    information to find the place you're searching for in your area. We will
    only share this information with our mapping provider solely for the purpose
    of providing you with this service. We might share your geolocation data
    with third parties to display ads for places (like restaurants) in your
    area. If you do not wish to allow us to share your information in this
    manner, you can opt-out by contacting us at support@bagtotee.com. You can
    voluntarily opt-out and stop receiving location-based services at any time
    by editing the device-level settings or by sending us an email at
    support@bagtotee.com. We use mobile analytics software to help us understand
    the functionality of our mobile software on your phone. This software may
    record information such as how often you use the application, the events
    that occur within the application, aggregated usage, performance data, and
    where the application was downloaded from. We do not link the information
    stored within the analytics software to any personally identifiable
    information you submit through the mobile application. Third-party Links
    From time to time, at our discretion, we may include or offer third-party
    products or services on our website. These third-party sites have separate
    and independent privacy policies. Therefore, we have no responsibility or
    liability for the content and activities of these linked sites. However, we
    seek to protect the integrity of our site and welcome any feedback about
    these sites. Third-party information technologies are used within the
    Bagtotee application through BagToTee. and our marketing partners. These
    technologies are used to monitor the application's use and track events
    within the app, and information from these technologies can be provided both
    individually and in aggregate. California Online Privacy Protection Act
    (CalOPPA) CalOPPA is the first state law in the nation to require commercial
    websites and online services to post a privacy policy. According to CalOPPA,
    we agree to the following: Once this privacy policy is created, we will add
    a link to it on our homepage or at the very least, on the first significant
    page after entering our website. Our Privacy Policy link includes the word
    'Privacy' and can easily be found on the specified page. Third-party Links
    Users will be notified of any Privacy Policy changes: On our Privacy Policy
    Page. Users can change their personal information by: Calling us. How does
    our site handle "do-not-track" signals? Currently, we do not recognize
    "do-not-track" signals from your browser. We agree with third parties, such
    as marketing or analysis partners, who may gather information about your
    online activities over time and across different websites after you end
    using our websites. COPPA (Children Online Privacy Protection Act) When it
    comes to the collection of personal information from children under 13, the
    Children's Online Privacy Protection Act (COPPA) puts parents in control.
    The Federal Trade Commission, the nation's consumer protection agency,
    enforces the COPPA Rule, which spells out what operators of websites and
    online services must do to protect children's privacy and safety online. We
    do not specifically market to children under 13. Social Media Widgets Our
    website includes social media features, such as the Facebook Like button and
    Widgets, like the Share this button or interactive mini-programs that run on
    our site. These features may collect your IP address and which page you are
    visiting on our site, and may set a cookie to enable the feature to function
    properly. Social media features and widgets are either hosted by a third
    party or hosted directly on our websites. Your interactions with these
    features are governed by the privacy policy of the company providing them.
    Changes to this Policy We may modify this policy at any time, and you will
    be notified by posting a revised version on our website. If we need to make
    a significant change to how we collect, use, or store your personal
    information, we will provide prior notice on our website or via email.
    California Residents: If you have any claims regarding any of the matters
    related to money transmission activities carried out on this site, you can
    contact the California Department of Business Oversight at the toll-free
    number, 1-866-275-2677, by email at consumer.services@dbo.ca.gov, or by
    postal mail at: Department of Business Oversight, Consumer Services, 1515 K.
    Street, Suite 200, Sacramento, CA, 95814. Contact Us If you have any
    inquiries regarding this Privacy Policy, you can contact us using the
    information provided below. Cookies A cookie is a small text file that is
    downloaded onto devices such as a computer or smartphone when the user
    accesses a website. It allows the website to recognize the user's device and
    store some information about the user's preferences or past actions. We use
    cookies to enhance your site experience, to assess content usage, and to
    support the marketing of our services. We aim to be completely transparent
    about the cookies we use and to make their control as easy as possible for
    you. Our cookies are categorized into five categories, each represented by a
    section. Each section provides a summary, which can be expanded for more
    detail. You can also download a document containing details of all the
    cookies we use. Necessary cookies are crucial for the website's underlying
    operation, supporting essential functions like shopping baskets and the
    website's technical operation. Site experience cookies support your
    experience on the site and include user-selected options and site navigation
    aids. Performance & operation cookies manage the site and include customer
    surveys, recording visitor numbers, and other web analytics. Marketing
    cookies track visitors across our sites. They can be used to build a profile
    of search and/or browsing history for each visitor. Identifiable or unique
    data may be collected. Any activity tracked and recorded using these cookies
    may be shared with third parties.
  </p>
</div>
